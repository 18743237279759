import { IncomingMessage, ServerResponse } from "http";
import { NextApiRequestCookies } from "next/dist/server/api-utils";
import { HTMLInputTypeAttribute, ReactElement } from "react";

export type ServerRequest = IncomingMessage & {
    cookies: NextApiRequestCookies;
} & {
    accessToken: string | null;
    locale: string | null;
    defaultLocale: string | null;
    locales: null | string[];
    isBlocked: boolean;
    query?: {
        [key: string]: string | string[];
    };
};

export type WindowType = Window &
    typeof globalThis & { interval: NodeJS.Timeout & number };

export type Autocomplete =
    | "given-name"
    | "family-name"
    | "email"
    | "postal-code"
    | "tel";
export interface Image {
    id: string;
    imageData?: string;
    mimeType: string;
    size: number;
    originalName: string;
    fullPath: string;
    thumbnails: [
        {
            path: string;
            dimension: string;
        },
    ];
}

export type HiddenAttributesKeys =
    | "color"
    | "displayOnExitWindow"
    | "showOnThankYou"
    | "scoreColor"
    | "discountColor"
    | "promoCode"
    | "phoneNumber"
    | "forgroundButtonColor"
    | "backgroundButtonColor"
    | "sellingPointsCheckBoxColors"
    | "offerPriceColor"
    | "discountColor"
    | "hideFromHeader"
    | "offerPlacement"
    | "discountValue"
    | "scoreValue"
    | "scoreMsg"
    | "sellingPoint"
    | "phoneNumberMessage"
    | "callUsPopupTitle"
    | "callUsPopupDescription"
    | "buttonText";

// type AttributesKeys =
//     | "scoreColor"
//     | "scoreValue"
//     | "scoreMsg"
//     | "devices"
//     | "promoCode"
//     | "phoneNumber"
//     | "phoneNumberMessage"
//     | "stickyOfferMessage"
//     | "stickyOfferBtnLabel"
//     | "discountValue"
//     | "sellingPoint";

export type SecondServiceCategoryElement = {
    TCPAMessage: string;
    appetite: null;
    autoListing: boolean;
    createdAt: string;
    description: string;
    headline: string;
    id: number;
    isDeleted: boolean;
    isPublished: boolean;
    label: string | null;
    languages: string[];
    name: string;
    order: number | null;
    slug: string;
    slugAlias: string;
    subtitle: string;
    supportedCountries: string[];
    title: string;
    updatedAt: string;
    icon: Image;
};
export type SecondServiceCategory = {
    category_id: number;
    createdAt: string;
    deletedAt: string;
    id: number;
    isDeleted: boolean;
    order: number;
    secondServiceCategory: SecondServiceCategoryElement;
    second_service_category_id: number;
    updatedAt: string;
};
export type Category = {
    id: number;
    name: string;
    slug: string;
    label: string | null;
    icon: Image | null;
    image: Image | null;
    title: string | null;
    slugAlias: string;
    crossSellingDomainFormId: string | null;
    secondServiceCategories?: SecondServiceCategory[];
    description?: string | null;
    headline?: string | null;
    metatags?: { key: string; value: string }[];
    isOneToOneConsent: boolean;
    config?: {
        tcpaPosition?: "aboveSubmitButton";
    };
};

export interface ListingItem {
    id: number;
    slug: string;
    title: string;
    description: string;
    displayUrl: string;
    logo: Image | null;
    image: Image | null;
    features: string[];
    category: Category;
    attributes: { [x: string]: string };
    hiddenAttributes: { [key in HiddenAttributesKeys]: string };
    oldPrice: string;
    price: string;
    discount: string;
    currency: string;
    isPhoneNumber: boolean | undefined;
    phoneLabel: string | undefined;
    phoneValue: string | undefined;
    link: string | undefined;
    sticky: boolean;
    previewDescription?: string;
    score?: string;
    proxiedNumber: {
        phoneNumber: string;
        category: string;
        categoryId: number;
        categorySlug: string;
        ts: string;
        offerId: number;
    } | null;
    placements: {
        sticky: boolean;
        showOnThankYou: boolean;
        hideNumberOnHeader: boolean;
        displayOnExitWindow: boolean;
        skipMainOffersList: boolean;
    };
    buyer?: {
        playName: string | null;
        id: number;
        name: string;
    };
    textBlocks?: TextBlock;
}

export interface Meta {
    key: string;
    value: string;
}

type MinMaxValue =
    | { value: Date | null; type: "date" }
    | { value: string | null; type: "number" };

export interface Option {
    id: number | undefined;
    label: string;
    value: string;
    icon: null | Pick<Image, "fullPath">;
}

export interface FieldConditionalLogic {
    isActive: true;
    action: "show" | "hide";
    operator: "AND" | "OR";
    requiredWhenShown: boolean;
    conditions: {
        codeName: string;
        operator: "equal" | "notEqual";
        value: string;
    }[];
}

export interface FieldDataDependency {
    source: "lookups" | "variations";
    type: string;
    ordering:
        | undefined
        | {
              field: "order" | "label";
              direction: "asc" | "desc";
          };
    dependency:
        | null
        | {
              fieldCodeName: string;
              dependentOn: "lookups" | "variations";
              type: string;
          }[];
}

export interface FieldMeta {
    key: string;
    value: string;
}

export type FieldMetaKeys =
    | "tooltip"
    | "fieldDescription"
    | "reverseOptions"
    | "canadianLabel"
    | "postalCodeErrorMsg"
    | "postalCodeErrorMsgEs"
    | "customLabel"
    | "expandable"
    | "step"
    | "maxValueLabel"
    | "minValueLabel"
    | "step"
    | "rangeValueFormatter"
    | "stateRegulation"
    | "forceShowOffers"
    | "icons"
    | "showCharCount"
    | "zipcodeDetailsPosition";

export interface Field {
    label: string | null;
    codeName: string;
    defaultValue: string | null;
    defaultValues: {
        options: Option[];
    } | null;
    dataDependency: null | FieldDataDependency;
    validationType:
        | "zipCode"
        | "firstName"
        | "lastName"
        | "email"
        | "phoneNumber"
        | "companyName"
        | "streetAddress";

    fieldType: FieldTypes;
    placeholder: string | null;
    isRequired: boolean;
    pattern: null | string;
    errorMessage: string | null;
    minValue: MinMaxValue | null;
    maxValue: MinMaxValue | null;
    note: string | null;
    autocomplete: string | null;
    title: string | null;
    mask: null | "us-phone" | "zipCode";
    meta: null | { [key in FieldMetaKeys]?: string };
    niceName: string | null;
    dependency: null | {
        fieldCodeName: string;
        data: { [x: string]: string[] };
    };
    conditionalLogic: null | FieldConditionalLogic;
    styles?: {
        withTooltip?: boolean;
        perPage?: number;
        withSearch?: boolean;
    };
    fetchingFieldData?: boolean;
    tag?: string;
}

export type FieldTypes =
    | "text"
    | "hidden"
    | "email"
    | "number"
    | "tel"
    | "textarea"
    | "date"
    | "boolean"
    | "radio"
    | "checkbox"
    | "select"
    | "multiSelect"
    | "streetAddress"
    | "zipCodeExpandable"
    | "multipleSelect"
    | "range"
    | "hidden"
    | "calculatedYears";

export interface Step {
    title: string | undefined;
    description: string;
    buttonText: string;
    fields: Field[];
    id: number;
    TCPAMessage: string | undefined;
    includeTCPAMessage: boolean;
    tooltip: string | null;
    tag?: string;
}

export type BlockClasses = {
    titleClass: string | undefined;
    blockClass: string | undefined;
};
export const periods = {
    PERIOD_FIXED_10YEARS: "10-Year Fixed ",
    PERIOD_FIXED_15YEARS: "15-Year Fixed ",
    PERIOD_FIXED_20YEARS: "20-Year Fixed ",
    PERIOD_FIXED_30YEARS: "30-Year Fixed (Default)",
    PERIOD_FIXED_40YEARS: "40-Year Fixed ",

    PERIOD_ARM_1YEARS: "1-Year ARM ",
    PERIOD_ARM_3YEARS: "3-Year ARM ",
    PERIOD_ARM_5YEARS: "5-Year ARM ",
    PERIOD_ARM_7YEARS: "7-Year ARM ",
    PERIOD_ARM_10YEARS: "10-Year ARM ",

    PERIOD_ARM_3YEARSIO: "3-Year ARM I/O",
    PERIOD_ARM_5YEARSIO: "5-Year ARM I/O",
    PERIOD_ARM_7YEARSIO: "7-Year ARM I/O",
};
export interface ClickOffer {
    title: string;
    bulletedDescription?: string[];
    logoUrl: string;
    brandName: string;
    slug: string;
    revenue: number | undefined | string;

    isMortgage?: boolean;
    desc?: string;
    type?: "rate" | "text";
    bulletDescription?: string[];
    YSP?: string;
    points?: string;
    APR?: string;
    rate?: string;
    monthlyPayment?: string;
    fees?: string;
    pointsAmount?: string;
    feesAmount?: string;
    phone?: string;
    // progoramType?: string;
    editorial?: string;
    rate_lock_used?: string;
    nmls?: string;
    license?: string[];
    period?: keyof typeof periods;
}

export type FilterQuestion =
    | {
          label: string;
          type: "radio" | "select" | "toggle";
          options?: { label: string; value: string }[];
          value: string;
          name: string;
          placeholder?: string | undefined;
          isInHomePage?: boolean | undefined;
          fieldType?: never;
          mask?: never;
          maxLength?: never;
      }
    | {
          label: string;
          type: "text";
          options?: never;
          value: string;
          name: string;
          placeholder?: string | undefined;
          isInHomePage?: boolean | undefined;
          fieldType: HTMLInputTypeAttribute;
          mask: "zipCode" | null;
          maxLength?: number | undefined;
      };

export interface ContactUsForm {}

export interface ContactUsField {
    name: string;
    label: string;
    placeholder: string;
    inputType:
        | "text"
        | "textarea"
        | "recaptcha"
        | "checkbox"
        | "checkbox-group"
        | "select"
        | "streetAddress"
        | "hidden"
        | "multiSelect";
    type: "tel" | "text" | "email" | null;
    validation: {
        required: boolean;
        rules?: {
            type: "isEmpty" | "isEmail" | "isPhone" | "isStreetAddress";
            characterCount?: { min: number; max: number };
        };
    };
    isExtraField?: boolean;
    autocomplete?: Autocomplete;
    defaultValue?: string;
    serverSideValidation?: boolean;
    options?: { label: string; value: string; id: number }[];
    rows?: number;
}

export interface WorkingHoursDay {
    dayName: string;
    startTime: string;
    endTime: string;
    isOff: boolean;
}

export type JSONValue = { [x: string]: string };

export type TranslationContent = {
    content: { [x: string]: string };
};

export interface PopupStyle {
    actionBtnBgColor?: string;
    actionBtnColor?: string;
    iconColor?: string;
}

export interface PopupProps {
    popupStyle?: PopupStyle;
    offerAsProp?: ListingItem;
    actionBtnBgColor?: string;
    actionBtnColor?: string;
    iconColor?: string;
}

export interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}
export interface FormattedAddressComponent {
    [x: string]: {
        long_name: string;
        short_name: string;
        types: string[];
    };
}

export interface SearchResult {
    address_components: AddressComponent[];
    formatted_address: string;
    geometry: {};
    partial_match: boolean;
    place_id: string;
    types: string[];
}

export interface AddressInfoResponse {
    data: {
        results: SearchResult[];
        status: string;
    };
}
export interface ExpandableFieldMeta {
    [x: string]: {
        addressComponentType: string;
        ignoreField: string;
        contentType: string;
        content: string;
    };
}
export interface FieldsData {
    [x: string]: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };
}
export interface Colors {
    fieldTextColor?: string;
    fieldLabelColor?: string;
    primaryColor?: string;
    textColor?: string;
    progressBar?: string;
    lightPrimaryColor?: string;
    multiSelectTagColor?: string;
}
export interface SecondServiceColors {
    mainBorderColor?: string;
    mainBackgroundColor?: string;
    hoverRadioColor?: string;
    selectedRadioColor?: string;
    textHoverRadioColor?: string;
    textSelectedRadioColor?: string;
    hoverCheckboxColor?: string;
    selectedCheckboxColor?: string;
    textHoverCheckboxColor?: string;
    textSelectedCheckboxColor?: string;
}
export interface InnerSecondServiceColors {
    mainBorderColor?: string;
    mainBackgroundColor?: string;
    hoverRadioColor?: string;
    selectedRadioColor?: string;
    textHoverRadioColor?: string;
    textSelectedRadioColor?: string;
    hoverCheckboxColor?: string;
    selectedCheckboxColor?: string;
    textHoverCheckboxColor?: string;
    textSelectedCheckboxColor?: string;
    fieldTextColor?: string;
    fieldLabelColor?: string;
    primaryColor?: string;
    textColor?: string;
    progressBar?: string;
    lightPrimaryColor?: string;
}

export interface StreetAddressProps {
    field: Field;
    fields: Field[];
    onChange: (field: Field, val: string) => void;
    value: string;
    className: string;
    type: React.HTMLInputTypeAttribute | undefined;
    fieldsData: FieldsData;
    colors: Colors;
    stepStatus: string;
    noteIndent?: string;
}
export interface StreetAddress {
    streetAddress?: string;
    streetAddress2?: string;
    streetNumber?: string;
    address?: string;
    route?: string;
    zipCode?: string;
    country?: string;
    city?: string;
    state?: string;
    toZipCode?: string;
    toCountry?: string;
    toCity?: string;
    toState?: string;
    toStateCode?: string;
    toCountryCode?: string;
}
export interface ExpandableFieldsObject {
    [x: string]: {
        addressComponentType: string;
        contentType: string;
        ignoreField: string;
        format?: string;
    };
}

export interface OfferListToShow {
    place: string;
    offers: ListingItem[] | [];
    req: ServerRequest;
    res: ServerResponse;
    categorySlug?: string;
    isMobile?: boolean;
}
export interface offerPlacement {
    offersList: boolean;
    thankYou: boolean;
    header: boolean;
    exitModal: boolean;
    showStickyOffer: boolean;
}

export interface TextBlock {
    category: { id: number; name: string; modelType: string };
    categoryId: number;
    createdBy: string;
    domainId: number;
    id: number;
    isActive: boolean;
    modelType: string;
    offersId: number[];
    position: string;
    queryString: any;
    text: string;
    translations: any;
    updatedBy: string;
    user: any;
}

interface IconType {
    [direction: string]: ReactElement;
}

export interface IconsLookup {
    [iconType: string]: IconType;
}
