export const getWidgetType = (widgetTag: string) => {
    const typeRegex = /type=(\w+)/;
    const typeMatch = widgetTag.match(typeRegex);
    if (typeMatch) {
        const widgetType = typeMatch[1];
        return widgetType;
    }
    return "notFound";
};
export const extractWidgets = (body: string) => {
    const widgetsArrayAfterSplitting = body.split(
        /\[widget type=[a-zA-Z0-9]+\]/i,
    );
    const widgetRegex = /\[widget type=[a-zA-Z0-9]+\]/gi;
    const widgets = body.match(widgetRegex);

    return [widgets ? widgets : null, widgetsArrayAfterSplitting];
};
export const removeParagraphById = (
    htmlString: string,
    targetId: string | string[] | undefined,
): string => {
    if (!targetId) return htmlString; // If undefined, return original HTML

    // Convert targetId to an array if it's a single string
    const ids = Array.isArray(targetId) ? targetId : [targetId];

    // Construct regex pattern to match any of the given IDs
    const regex = new RegExp(
        `<p[^>]*id=["'](${ids.join("|")})["'][^>]*>.*?</p>`,
        "gs",
    );

    return htmlString.replace(regex, "");
};
